import React from 'react'
import {graphql} from 'gatsby'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import '../styles/global.scss'
import '../styles/blog-post.scss'
import {PostFeaturedImage} from "../components/PostFeaturedImage/PostFeaturedImage";

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.mdx
        const siteTitle = this.props.data.site.siteMetadata.title
        const {previous, next} = this.props.pageContext
        console.log(this.props.pageContext)

        const postFeaturedImage = () => <PostFeaturedImage frontmatter={post.frontmatter}/>

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title={post.frontmatter.title} description={post.excerpt}/>
                <h1>{post.frontmatter.title}</h1>
                <p className='post-date'>
                    {post.frontmatter.date}
                </p>
                <MDXRenderer postFeaturedImage={postFeaturedImage}>
                    {post.body}
                </MDXRenderer>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const blogPostQuery = graphql`
  query blogPostQuery($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        published
        title
        date(formatString: "DD MMMM, YYYY")
        category
        tags
        featuredImage {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }        
      }
      body
    }
  }
`
