import React from 'react'
import Img from "gatsby-image"
import './postFeaturedImage.scss'

export const PostFeaturedImage = ({frontmatter}) => {

    if (frontmatter.featuredImage) {
        return <Img className='ui large rounded left floated image post-featured-image'
                    fluid={frontmatter.featuredImage.childImageSharp.fluid}
                    title={frontmatter.title}/>
    }
}
